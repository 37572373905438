var render = function () {
  var _vm$reservationsData$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AppCollapse', {
    staticClass: "mb-50",
    attrs: {
      "type": "border-info-2 card"
    }
  }, [_c('AppCollapseItem', {
    staticClass: "border-info-2",
    attrs: {
      "is-visible": "",
      "title": "",
      "class-header": "card-header bg-blue-gradient p-0 m-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-1"
        }, [_c('b-img', {
          staticClass: "icon-card",
          attrs: {
            "src": require("@icons/suitcase.svg"),
            "width": "50px"
          }
        }), _c('h6', {
          staticClass: "text-airline font-medium-3 m-0 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.otherServiceProduct')) + " ")])], 1), !_vm.isHideFunctionButton && !_vm.isRoleF3 && !_vm.hideFeature && _vm.reservationsData.status !== 'CANCEL' ? _c('div', {
          class: _vm.isMobileView ? 'd-flex-center flex-wrap' : '',
          staticStyle: {
            "margin-right": "2.5rem"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.window",
            modifiers: {
              "hover": true,
              "window": true
            }
          }],
          staticClass: "p-50 rounded-circle mx-25",
          attrs: {
            "variant": "flat-dark",
            "title": "".concat(_vm.$t("reservation.".concat(_vm.showServiceFee ? 'Hide' : 'Show')), " ").concat(_vm.$t('reservation.priceDetails.titleTable.feeService'))
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              _vm.showServiceFee = !_vm.showServiceFee;
            }
          }
        }, [_vm.showServiceFee ? _c('img', {
          attrs: {
            "src": require("@icons/money.svg")
          }
        }) : _c('img', {
          attrs: {
            "src": require("@icons/not_money.svg")
          }
        })])], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('b-card-body', {
    staticClass: "d-flex flex-column gap-3 py-25 px-0"
  }, [!_vm.isProduction ? _c('div', [!_vm.isEmpty(_vm.reservationsData.specialServiceInsurance) && _vm.reservationsData.specialService ? _c('div', {
    staticClass: "fw-700 text-airline"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Business lounge\']')) + " ")]) : _vm._e(), _c('b-table', {
    staticClass: "mb-0 border-secondary rounded-8px",
    attrs: {
      "centered": "",
      "responsive": "",
      "small": "",
      "bio": "",
      "hover": "",
      "bordered": "",
      "thead-class": "text-body text-center align-middle text-nowrap",
      "tbody-class": "text-body align-middle text-nowrap",
      "show-empty": "",
      "empty-text": _vm.$t('reservation.noAddons'),
      "fields": _vm.loungeTableColumns,
      "items": _vm.resolveSpecialServices(_vm.reservationsData)
    },
    scopedSlots: _vm._u([_vm._l(_vm.loungeTableColumns, function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: indexColumn
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(qrcode)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-button', {
          staticClass: "d-flex-center text-nowrap px-75",
          staticStyle: {
            "max-width": "300px"
          },
          attrs: {
            "variant": "outline-primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.showSpecialServicesQRCode(item);
            }
          }
        }, [_c('span', {
          staticClass: "font-small-4",
          staticStyle: {
            "padding-bottom": "1px"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'information & QR\']')) + " ")])])];
      }
    }, {
      key: "cell(itinerary)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "fw-700"
        }, [_c('span', {
          staticClass: "text-airline"
        }, [_vm._v(_vm._s(item.flightNumber.split(' ').join('')))])]), _c('div', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(item.flightTrip.split(' ').join('-')) + " ")])];
      }
    }, {
      key: "cell(services)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(item.airportLounge.name) + " (" + _vm._s(item.airportLounge.isDomestic ? _vm.$t('reservation[\'domestic\']') : _vm.$t('reservation[\'international\']')) + ") ")]), _c('div', {
          staticClass: "fw-600"
        }, [_vm._v(" " + _vm._s(item.airport.name) + " ")])];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return _vm._l(item.bookingFares, function (lounge, indexLounge) {
          return _c('div', {
            key: indexLounge,
            staticClass: "w-100 d-flex-between"
          }, [_c('span', {
            staticClass: "fw-700 pr-50"
          }, [_vm._v(" " + _vm._s(lounge.airportLoungeFare.name) + " (x" + _vm._s(lounge.count) + ") ")]), _c('span', {
            staticClass: "font-italic font-small-3 font-weight-bolder text-warning"
          }, [_vm._v(" (" + _vm._s(_vm.formatCurrency(lounge.price)) + ") ")])]);
        });
      }
    }, {
      key: "cell(status)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "fw-700 p-50 rounded text-center text-success border-success"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'paid\']')) + " ")])];
      },
      proxy: true
    }, {
      key: "cell(total)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "fw-700 text-right px-50"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.servingPrice)) + " ")])];
      }
    }], null, true)
  }), !_vm.isEmpty(_vm.reservationsData.specialService) ? _c('div', {
    staticClass: "d-flex justify-content-end m-1"
  }, [_c('div', {
    staticClass: "mr-1 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'totalPrice\']')) + " ")]), _c('div', {
    staticClass: "fw-700 text-right font-medium-2 text-airline"
  }, [_vm._v(" " + _vm._s(_vm.getTotalPriceSpecialService(_vm.reservationsData.specialService)) + " VND ")])]) : _vm._e()], 1) : _vm._e(), !_vm.isEmpty(_vm.reservationsData.specialServiceInsurance) ? _c('div', [_c('div', {
    staticClass: "fw-700 text-airline"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'insuranceServices\']')) + " ")]), _c('b-table', {
    staticClass: "mb-0 border-secondary rounded-8px",
    attrs: {
      "centered": "",
      "responsive": "",
      "small": "",
      "bio": "",
      "hover": "",
      "bordered": "",
      "thead-class": "text-body text-center align-middle text-nowrap",
      "tbody-class": "text-body text-center align-middle text-nowrap",
      "show-empty": "",
      "items": _vm.resolveInsuranceServices(_vm.reservationsData),
      "fields": _vm.insuranceTableColumns,
      "empty-text": _vm.$t('reservation.noAddons')
    },
    scopedSlots: _vm._u([_vm._l(_vm.insuranceTableColumns, function (column, indexColumn) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: indexColumn
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(passenger)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.passengerName) + " ")])];
      }
    }, {
      key: "cell(itinerary)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-airline fw-700 mb-25"
        }, [_vm._v(" " + _vm._s(_vm.resolveInsuranceItinerariesName(item.flightJourney, 'journeys')) + " ")])];
      }
    }, {
      key: "cell(services)",
      fn: function fn() {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation[\'insuranceServices\']')) + " ")])];
      },
      proxy: true
    }, {
      key: "cell(serviceName)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.planName) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('b-badge', {
          staticClass: "py-50 fw-700 w-100",
          attrs: {
            "variant": _vm.resolveVariantInsuranceServices(item.status)
          }
        }, [_vm._v(" " + _vm._s(_vm.resolveInsuranceSpecialServicesName(item.status)) + " ")])];
      }
    }, {
      key: "cell(insurancePrice)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "fw-700 px-50"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.price)) + " ")])];
      }
    }, {
      key: "cell(priceToPay)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "fw-700 px-50"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice)) + " ")])];
      }
    }, {
      key: "cell(benefit)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "fw-600 text-right px-50"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.priceCommission)) + " ")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [!['PENDING_SUBMISSION'].includes(item.status) ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "p-50 mr-50 rounded fw-700",
          attrs: {
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowInsuranceInfo(item);
            }
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation[\'detail\']')) + " ")])]) : _vm._e(), ['PENDING_SUBMISSION'].includes(item.status) && !_vm.isRoleF3 ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "p-50 mr-50 rounded fw-700",
          attrs: {
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.handleCreateInsuranceCoverNote(item);
            }
          }
        }, [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'createCoverNote\']')) + " ")])]) : _vm._e(), ['PENDING_SUBMISSION'].includes(item.status) && !_vm.isRoleF3 ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "p-50 mr-50 rounded fw-700",
          attrs: {
            "variant": "outline-warning"
          },
          on: {
            "click": function click($event) {
              return _vm.handleUpdateInsuranceDraft(_vm.reservationsData, item);
            }
          }
        }, [_c('span', {
          staticClass: "fw-700"
        }, [_vm._v(_vm._s(_vm.$t('reservation[\'updateCover\']')))])]) : _vm._e(), ['PENDING_PAYMENT'].includes(item.status) && !_vm.isRoleF3 ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "p-50 mr-50 rounded fw-700",
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              return _vm.handleCreateInsurancePolicy(item, _vm.reservationsData);
            }
          }
        }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'payment\']')) + " ")])]) : _vm._e(), ['PENDING_PAYMENT'].includes(item.status) && !_vm.isRoleF3 ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "p-50 mr-50 rounded fw-700",
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.handleCancelInsuranceItem(item, _vm.reservationsData);
            }
          }
        }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'cancelCover\']')) + " ")])]) : _vm._e(), ['PAID'].includes(item.status) && !_vm.isRoleF3 ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "p-50 mr-50 rounded fw-700",
          attrs: {
            "variant": "outline-warning"
          },
          on: {
            "click": function click($event) {
              return _vm.handleRefundInsuranceItem(item);
            }
          }
        }, [_c('span', {}, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'refundCover\']')) + " ")])]) : _vm._e()], 1)];
      }
    }], null, true)
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex-between w-100"
  }, [!_vm.isRoleF3 ? _c('div', {
    staticClass: "px-1 py-50 rounded d-flex-center align-items-start flex-column",
    style: "border: 1px solid ".concat(_vm.showBenefit ? '#E6E6E6' : 'transparent', "; height: 72px")
  }, [_c('div', [!_vm.hideFeature ? _c('BButton', {
    staticClass: "p-25 rounded-circle",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.showBenefit = !_vm.showBenefit;
      }
    }
  }, [_vm.showBenefit ? _c('feather-icon', {
    attrs: {
      "icon": "EyeIcon",
      "size": "16"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "EyeOffIcon",
      "size": "16"
    }
  })], 1) : _vm._e()], 1), _vm.showBenefit ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation.benefit')) + ": ")]), _c('span', {
    class: "text-".concat(_vm.benefitInsurance > 0 ? 'success' : 'danger', " ml-50 font-medium-4")
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.benefitInsurance)) + " ")])]) : _vm._e()]) : _vm._e(), !_vm.isRoleF3 ? _c('div', [!_vm.isEmpty((_vm$reservationsData$ = _vm.reservationsData.specialServiceInsurance) === null || _vm$reservationsData$ === void 0 ? void 0 : _vm$reservationsData$.filter(function (i) {
    return _vm.TO_BE_PAID_INSURANCE_ITEM.includes(i.status);
  })) ? _c('div', [_c('b-alert', {
    staticClass: "p-1 text-center d-flex-center flex-nowrap fw-700",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_c('div', {
    staticClass: "mr-75 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'totalAmountToPay\']')) + " ")]), _c('div', {
    staticClass: "fw-700 text-right font-medium-2 text-airline text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalAmountInsToBePay)) + " VND ")])])], 1) : _vm._e(), _vm.showServiceFee ? _c('div', {
    staticClass: "mt-50 text-right"
  }, [_c('span', {
    staticClass: "text-body font-medium-1"
  }, [_vm._v(_vm._s(_vm.$t('reservation.pricePaidToSupplier')) + ":")]), _c('span', {
    staticClass: "text-success ml-50 font-medium-4"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalAllInsurance)) + " VND ")])]) : _vm._e()]) : _vm._e()])])], 1)], 1), !_vm.hideFeature && !_vm.isHideFunctionButton ? _c('div', {
    staticClass: "d-flex-center justify-content-end my-50 gap-1 flex-wrap"
  }, [!_vm.isRoleF3 ? _c('b-button', {
    staticClass: "d-flex-center text-nowrap px-75 gap-1",
    staticStyle: {
      "max-width": "300px"
    },
    attrs: {
      "variant": "primary",
      "disabled": ['CANCEL'].includes(_vm.reservationsData.status),
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.handleShowModalAddInsuranceDraft(_vm.reservationsData);
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/increase.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-medium-1",
    staticStyle: {
      "padding-top": "1px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('reservation[\'createInsurance\']')))])])], 1) : _vm._e(), !_vm.isProduction ? _c('b-button', {
    staticClass: "d-flex-center text-nowrap px-75 gap-1",
    staticStyle: {
      "max-width": "300px"
    },
    attrs: {
      "variant": "primary",
      "disabled": !_vm.canAccess('booking.addBag') || _vm.isBookingOutSystem,
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.showAddOtherSpecialServices(_vm.reservationsData);
      }
    }
  }, [_c('b-img', {
    attrs: {
      "src": require("@icons/increase.svg"),
      "width": "16"
    }
  }), _c('span', {
    staticClass: "font-medium-1",
    staticStyle: {
      "padding-top": "1px"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('reservation[\'createLounge\']')))])])], 1) : _vm._e()], 1) : _vm._e(), !['CANCEL'].includes(_vm.reservationsData.status) ? _c('ModalAddInsuranceService', {
    attrs: {
      "booking-data": _vm.reservationsData,
      "pax-avail-to-add-draft-insurance": _vm.paxAvailToAddDraftInsurance,
      "data-insurance-draft": _vm.dataInsuranceDraft,
      "is-edit": _vm.isEdit
    },
    on: {
      "update:paxAvailToAddDraftInsurance": function updatePaxAvailToAddDraftInsurance($event) {
        _vm.paxAvailToAddDraftInsurance = $event;
      },
      "update:pax-avail-to-add-draft-insurance": function updatePaxAvailToAddDraftInsurance($event) {
        _vm.paxAvailToAddDraftInsurance = $event;
      },
      "update:dataInsuranceDraft": function updateDataInsuranceDraft($event) {
        _vm.dataInsuranceDraft = $event;
      },
      "update:data-insurance-draft": function updateDataInsuranceDraft($event) {
        _vm.dataInsuranceDraft = $event;
      },
      "update:isEdit": function updateIsEdit($event) {
        _vm.isEdit = $event;
      },
      "update:is-edit": function updateIsEdit($event) {
        _vm.isEdit = $event;
      }
    }
  }) : _vm._e(), _c('ModalCreateInsuranceCoverNote', {
    attrs: {
      "booking-data": _vm.reservationsData,
      "data-insurance-draft": _vm.dataInsuranceDraft
    },
    on: {
      "update:dataInsuranceDraft": function updateDataInsuranceDraft($event) {
        _vm.dataInsuranceDraft = $event;
      },
      "update:data-insurance-draft": function updateDataInsuranceDraft($event) {
        _vm.dataInsuranceDraft = $event;
      }
    }
  }), _c('ModalShowInsuranceInfo', {
    attrs: {
      "booking-data": _vm.reservationsData,
      "data-insurance-item": _vm.dataInsuranceItem
    }
  }), _c('ModalRefundInsurance', {
    attrs: {
      "booking-data": _vm.reservationsData,
      "data-insurance-item": _vm.dataInsuranceItem
    }
  }), _c('ModalAddLoungeService', {
    attrs: {
      "booking-data": _vm.reservationsData
    }
  }), _c('ModalShowQRCode', {
    attrs: {
      "booking-data": _vm.reservationsData,
      "data-special-item": _vm.dataSpecialItem
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }